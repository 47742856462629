/*
* @Author: heyang
* @Description:
* @ModuleName:
* @Date: 2018-09-25 20:18:25
 * @Last Modified by: WYY
 * @Last Modified time: 2020-03-03 17:11:52
*/
<!-- unit 描述 -->
<style lang="less" scoped>
        .Unit_con{
            .content_t{
                text-align: center;
                .Img_show{
                    height: 100%;
                    .deep{
                        height: 100%;
                    }
                }
            }
        }
    .question_des {
        position: relative;

        .Fangda {
            position: absolute;
            border: none;
            width: 30px;
            height: 30px;
            background-color: #637377;
            border-radius: 3px;
            opacity: 0.6;
            font-size: 21px;
            text-align: center;
            line-height: 30px;
            color: #fff;
            bottom: 0.4rem ;
            z-index: 30;
        }
    } 
</style>

<template>
        <div class="T_box">

            <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
            <!-- 题干音频 -->
            <!-- <video :id="`myElement`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
            <!-- 问题音频 -->
            <!-- <div id="Question" style="display:none !important;">Loading the player ...</div> -->
            <!-- 作答提示音 -->
            <!-- <video :id="`Hint`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
            <AudioPlayer ref="HintAudioPlayer"  :theUrl="HintAudioPlayerUrl" @audioEndedOver="HintPlayerComplete" :config="HintAudioPlayerConfig" />
            <div class="T_content">
                <div class="Unit_d">
                    <div class="Unit_con">
                        <!-- <div class="ShuiYinRight"> 中国民用航空西北地区空中交通管理局 </div> -->
                        <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                        <!-- 播放 -->
                        <div class="Second_title">
                            <div class="Audio_play">
                                <span class="t1" style="font-weight:bold;font-style:italic;">NO.
                                    {{questionIndex + 1}}/{{questionAll}}</span>
                                <div></div>
                                <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                    <img :src=" require( '@/assets/shuoming.png')" alt="">
                                    <span>Directions</span>
                                </div>
                            </div>
                        </div>
                            <div class="Second_title_describe">
                                <div class="Question_list" v-show="Pattern!='测试模式'">
                                    <span class="t1">Question.
                                        {{childQuestionListIndex + 1}}/{{childQuestionListAll}}</span>
                                </div>
                            </div>
                        

                        <div class="Question_box clearfix">
                            <div :class="['question_des',(threeActive=='')?'active':'']">
                                <div class="content_t">
                                    <div class="Img_show" v-html="this.questionTexts">
                                        
                                    </div>
                                </div>

                            </div>
                            <div class="Text" v-show="threeActive=='TEXT'">
                                <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i>
                                </div>
                                <div style="height:100%;">
                                    <div class="t_content" v-html="this.questionTexts" style="border:none"></div>
                                </div>
                            </div>
                            <div class="Notes" v-show="threeActive=='NOTES'">
                                <div class="title"> Notes <i class="el-icon-close right" @click="threeShowClose"></i>
                                </div>
                                <div class="t_content">
                                    <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()"
                                        maxlength="500" id="" cols="30" rows="10"></textarea>
                                    <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc"
                                        alt="">
                                    <button class="save" @click="SaveNotes"> Save </button>
                                </div>
                            </div>
                            <!-- key -->
                            <div class="Text" v-show="threeActive=='KEY'">
                                <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div class="t_content" v-html="this.AnswerKey"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="T_option">
                    <div class="Unit_option">
                        <div class="btn_list">
                            <!-- back -->
                            <span class="btn" @click="On_back()" v-if="Pattern!='测试模式'"></span>
                            <!-- next -->
                            <span class="btn2" @click="On_next()" v-if="Pattern!='测试模式'"></span>
                        </div>

                        <Time :Pattern='Pattern' />
                    </div>
                </div>
            </div>

            

            <!-- <div class="Machine" v-if="Pattern!='测试模式'">    
                <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">    
                <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">    
                <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt="" @click="mshow.text=1">    
                <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt="" style="cursor:not-allowed;">    
                <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt="" @click="Clicknotes">    
                <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt="" @click="mshow.notes=1">    
                <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt="" style="cursor:not-allowed;">    
                <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="CheckKey()">    
                <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">    
            </div>     -->
            <popup :show.sync="pshow" :describe='this.Target.description'></popup>
            <div class="Re_cover" v-if='RecordBtn'></div>
            <amplification :show.sync='aShow' :url='questionImg' />
        </div>
</template>

<script>
    // import recording from '../../js/luyin.js'    
    import popup from "../describe/Popup.vue";
    import amplification from "../../components/amplification.vue";
    import Head from '../../components/head.vue'
    import videoJs from 'video.js'
    import "video.js/dist/video-js.min.css"
    export default {
        data() {
            return {
                // 机器人按钮显示    
                mshow: {
                    text: 1,
                    notes: 1,
                    key: 1
                    // 0 不可选 1 可选 2 已选    
                },
                // 数据加载 判断 防止二次加载    
                Panduan: true,
                // 章节信息    
                Target: {
                    catalog: "",
                    description: "",
                    isEnd: null,
                    title: ""
                },
                // 左右 切换按钮显示    
                RightLeft: false,
                // 描述 弹窗显示    
                pshow: false,

                // 问题 数据    
                questionData: {},
                childQuestionData: {},
                questionTexts: "暂无原文",
                questionAudio: "",
                questionImg: "",

                questionIndex: 0,
                questionAll: 0,

                childQuestionListIndex: 0, // 当前子题    
                childQuestionListAll: 0, // 所有子题 长度    


                // 播放器 参数  命名 不要重复   mixin 中使用了 参数    
                GetTime: false, // 获取 视频总时长    
                audioTime: 0, // 滑块 value    
                player: null, // 播放器    
                playertime: "", //总时长    
                InPlay: false, // 判断是否 播放中    
                // 播放器 参数    


                check: "", // 判断是否选择 答案    
                complate: false, // 是否提交答案    
                answer: "", // 答案    
                AnswerKey: "",

                NoteItem: {
                    //笔记    
                    id: "",
                    content: ""
                },

                // 绑题规则    
                BindRole: {},
                // 问题音频    
                Question: {
                    audio: null,
                    ready: false,
                    status: false
                },
                // 录音
                RecordeItem: {
                    show: true,
                    time: null,
                    timeshow: "",
                    Count: null,
                    begin: false,
                    getAudio: null,
                    RecorderAudio: 0, //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media: null, // 录音播放 多媒体
                    MediaInPlay: false // 录音播放状态
                },
                QuestionParameter: {
                    width:'500',
                    height:'500',
                    controls:true,
                    loop:false,
                    preload:'auto',
                    autoplay:true,
                },

                wave: null,
                waveIndex: 0,
                // 树形 上层数据
                UpLayerData: {},
                InfoComplate: false, // 首次加载数据判断
                // 提示音
                Hint: null,
                //是否作答
                hasRecording: false,
                aShow: false, // 放大图片
                moveTime: null,
                QuestionAudioPlayerUrl:"",//问题音频地址
                QuestionAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[1,4],
                },
                HintAudioPlayerUrl:"",//提示音频地址
                HintAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                recorderPlayerUrl:"",//录音音频地址
                recorderPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
            };
        },
        components: {
            popup,
            amplification,
            Head
        },
        created() {
            if (this.menulist.length > 0) {
                this.Panduan = true;
                this.getinfo();
            } else {
                this.Panduan = false;
            }
        },

        mounted() {
        },
        computed: {
            datacomplate: function () {
                return this.$store.state.common.datacomplate;
            },
            checkid: function () {
                return this.$store.state.common.checkid;
            },
            RecordBtn: function () {
                return this.$store.state.common.RecordBtn;
            },
            testPause: function () {
                return this.$store.state.common.testPause;
            },
        },

        watch: {
            datacomplate: function (v) {
                if (!this.Panduan) {
                    this.getinfo();
                }
            },
            dataLayer: function () {
                this.getinfo();
            },
            checkid: function () {
                if (this.InfoComplate) {
                    this.getinfo();
                }
            },
            testPause: function (v, oldv) {
                if (this.Pattern == '测试模式') {
                    if (v && !oldv && !this.complate) { // 开始
                        if (!!this.QuestionChange) {
                            this.QuestionChange();
                        } else {
                            if (!!this.pauseBegin) this.pauseBegin();
                        }
                    }
                    if (oldv && !v) { // 暂停
                        if (!!this.stayPause) this.stayPause();
                    }
                }
            },
            threeActive: function (v) {
                if (!!this.moveTime) {
                    clearTimeout(this.moveTime);
                    this.moveTime = null;
                }
                this.moveTime = setTimeout(this.reLoad, 500);
                if (v == 'KEY' && !!this.CheckKey) {
                    this.CheckKey();
                }
            },
        },

        methods: {
            
            stayPause() { // 测试模式暂停
                if (this.complate) {
                    this.$message.warning('您本题已提交答案,无法暂停!');
                    this.$store.commit('setitem', {
                        name: 'testPause',
                        item: true
                    });
                    return false;
                }
                this.InPlay = false;
                if (!!this.Question.audio) this.Question.audio.pause();
                if (!!this.Hint) this.Hint.pause();
                if (!!this.twicePlayTimeOut) clearTimeout(this.twicePlayTimeOut)
                // 录音播放停止
                if (this.RecordeItem.MediaInPlay) {
                    if (this.RecordeItem.RecorderAudio == 1) {
                        this.RecordeItem.Media.pause();
                    }
                    this.RecordeItem.MediaInPlay = false;
                }
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    Recorder.stop();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                }
            },
            Wheight(i) {
                let t = i - 1;
                let c = Math.abs(Math.abs(Math.floor(t / 5) * 5 - (t % 5)) * 40 - 300) - 30;
                let d = 300 * 300 - c * c;
                d = Math.sqrt(d) * 2;
                return d;
            },

            async getinfo() {
                this.RecordeItem.Media = document.createElement("audio");
                let data = this.GetTargetItem();
                this.UpLayerData = data.updata; // 上 层数据
                if (!!data.last) {
                    this.Target = data.data[data.last];
                } else {
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                // 判断 学习模式
                this.$store.commit("setitem", {
                    name: "Pattern",
                    item: this.Target.bindRole.ruleTypeName
                });
                this.$store.commit('setitem', {
                    'name': 'RoleIsJudgment',
                    'item': this.Target.bindRole.isJudgment
                });
                console.log(this.Target);
                // 判断 绑题规则 设置 notes key txt
                this.BindRole = this.Target.bindRole;
                this.threeShow(this.BindRole);
                if (this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if (this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;
                
                // 判断 绑题规则 设置 notes key txt
                if (this.Target.isEnd == 1 && data.data.length > 1) {
                    this.RightLeft = true;
                } else {
                    this.RightLeft = false;
                }
                let qIndex = sessionStorage.getItem("questionIndex") || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem("SonquestionIndex") || 0; // 问题子题下标
                let item = this.Target.questionList[this.questionIndex];
                let mainId = '';
                if (this.Pattern == '测试模式' && !!this.Target.childList && this.Target.childList.length > 0) {
                    mainId = item.mainId;
                } else {
                    mainId = this.Target.sectionMain.id;
                }
                this.questionAll = this.Target.questionList.length; // 问题 总数
                let questiondata = await this.GetQuestion(
                    this.Target.questionList[qIndex].oldId,
                    mainId
                );
                
                this.questionData = questiondata.data; // 当前问题 数据
                if (this.Pattern == '测试模式') {
                    let dan = this.questionData.childQuestionList[item.num];
                    this.questionData.childQuestionList = [dan];
                }
                this.childQuestionListAll = this.questionData.childQuestionList.length; // 当前问题 子题总数

                // 当前子问题数据
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                var questionTextAndAudio = JSON.parse(this.childQuestionData.title);
                this.questionTexts = questionTextAndAudio.questionText
                this.questionAudio = questionTextAndAudio.questionAudio;
                this.questionData.textResource = this.questionData.textResource.replace('/Images/', '');
                this.questionImg = this.$store.state.common.ImgUrl + this.questionData.textResource;
                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;    
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if (!!record_.data && !!record_.data.filePath) {
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = "1";
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if (!!notes_.data && notes_.data.length > 0) {
                    this.NoteItem = notes_.data[0];
                } else {
                    this.NoteItem.content = "";
                }
                this.$store.commit("setitem", {
                    name: "RecordBtn",
                    item: false
                });
                console.log(this.BindRole,'BindRole');
                // 赋值音频路径
                this.QuestionAudioPlayerUrl = this.questionAudio
                this.QuestionAudioPlayerConfig.autoplay = true
                // let src = this.FlashUrl(this.questionAudio);    
                // this.FlashPlay(src); // 题干音频 设置   
                // this.QuestionParameter.autoplay = true;
                // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                // this.QuestionReady(); // 问题音频 设置
                this.InfoComplate = true;
            },

            async CheckKey() {
                this.mshow.key = 2;
                this.getAnswerData();
                if (this.mshow.notes != 0) this.mshow.notes = 1;
                if (this.mshow.text != 0) this.mshow.text = 1;
            },
            async getAnswerData() {
                let url_ = await this.AjaxApi.getAnswerById(this.childQuestionData.id);
                var AnswerData = JSON.parse(url_.data.answers);
                let answerKey = '';
                if (!!AnswerData.answerText && AnswerData.answerText != null) answerKey =
                    `<span>Answer</span><div>${AnswerData.answerText}</div> `;
                if (!!url_.data.answerKey && url_.data.answerKey != null) answerKey +=
                    `<span>Keyword</span><div>${url_.data.answerKey}</div>`;
                this.AnswerKey = answerKey;
            },

            // 保存 笔记    
            async SaveNotes() {
                let question = this.childQuestionData;
                let item = this.NoteItem;
                item.content = item.content.replace(/(^\s*)|(\s*$)/g, "");
                if (!!!item.content) {
                    this.$message.warning("请填写笔记!");
                    return false;
                }

                let layer = sessionStorage.getItem("targetceng").split(",");
                layer = layer.map(el => {
                    el = el.split(".");
                    return el;
                });

                let data = {
                    courseId: this.menulist[layer[0][1]].courceId,
                    nodeId: this.Target.id,
                    title: "",
                    content: item.content,
                    questionId: question.id,
                    levelName: this.Target.catalog
                };

                if (!!item.id) {
                    let edit_ = await this.AjaxApi.updateCourseNote({
                        id: this.childQuestionData.id,
                        content: item.content
                    });
                    if (edit_.code == "200") {
                        this.$message.success("修改笔记成功!");
                    }
                } else {
                    let add_ = await this.AjaxApi.addCourseAdvice(data);
                    if (add_.code == "200") {
                        this.$message.success("添加笔记成功!");
                        this.NoteItem.id = add_.data.id;
                    }
                }
            },

            
            async UpdateAudio() {
                this.ComplateTest = '1';
                await this.$refs.Recorder.stopVoice()
                // Recorder.upload({
                //     url: this.$store.state.common.UploadAudio,
                //     audioParam: "file",
                //     success: msg => {
                //         this.complate = true;
                //         this.AddAudio(msg);
                //     }
                // });
            },

            async AddAudio(url_) {
                this.recorderPlayerUrl = url_.data;
                let path = url_.data;
                // 新增录音记录      
                let data = {
                    courseId: this.$store.state.common.curriculum,
                    filePath: path,
                    questionId: this.childQuestionData.id,
                    sectionId: this.Target.id,
                    recordTime: this.recordTime,
                    seatMealCourseId: sessionStorage.getItem('seatMealCourseId') || 'abc',
                    uuid: sessionStorage.getItem('uuid'),
                };
                let add_ = await this.AjaxApi.addVideo(data);
                let fo = {
                    url: path,
                    type: '5',
                    bigTitleId: this.questionData.id,
                    smallTitleId: this.childQuestionData.id,
                    standardAnswer: '',
                }
                await this.GetScore(fo);
                if (this.Pattern == "测试模式") {
                    this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                        .questionAll);
                }
            },

            //  滑块 tooltip    
            formatTooltip(val) {
                if (!!this.player) {
                    let c = parseFloat((val / 100) * this.player.duration()).toFixed(2);
                    if (c != NaN && c != "NaN") c += "s";
                    else c = "s";
                    return c;
                } else {
                    return "";
                }
            },
            // 录音播放完成
            recorderPlayerAudioEndedOver(){
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay
            },
            MediaPlay() {
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                }
                this.$refs.recorderPlayer.startPlayOrPause()
                // if (this.RecordeItem.RecorderAudio == 0) {
                //     // 未录音    
                //     this.$message.warning("请先录制音频!");
                //     return false;
                // }

                // if (this.InPlay) {
                //     this.InPlay = false;
                //     this.Question.audio.pause();
                // }


                // if (this.RecordeItem.MediaInPlay) {
                //     if (this.RecordeItem.RecorderAudio == 1) {
                //         this.RecordeItem.Media.pause();
                //     }
                //     if (this.RecordeItem.RecorderAudio == 2) {
                //         Recorder.stop();
                //     }
                // } else {
                //     if (this.RecordeItem.RecorderAudio == 1) {
                //         this.RecordeItem.Media.play();
                //     }
                //     if (this.RecordeItem.RecorderAudio == 2) {
                //         Recorder.play({
                //             // 播放 监听    
                //             progress: function (milliseconds) {},
                //             finished: this.PlayChange,
                //         });
                //     }
                // }
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;
            },

            // 重新 播放

            ResetTime() {
                // 赋值音频路径
                this.QuestionAudioPlayerUrl = this.questionAudio
                this.$refs.QuestionAudioPlayer && this.$refs.QuestionAudioPlayer.startPlayOrPause()
                // this.QuestionParameter.autoplay = true;
                // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                // this.QuestionReady();
                // this.RecordeItem.Media.pause();    
                // this.RecordeItem.MediaInPlay = false;    
                // this.audioTime = 0;    
                // this.player.setVolume(100);    
                // this.player.seek(0);    
                this.InPlay = true;
                this.twicePlayTimeOut = null;
            },

            // 滑块  播放时段改变

            TimeChange() {
                this.RecordeItem.Media.pause();
                this.RecordeItem.MediaInPlay = false;
                let time = (this.audioTime / 100) * this.player.duration();
                this.player.currentTime(time);
                this.player.play();
                this.InPlay = true;
            },

            // 上一题    
            async prevQuestion() {
                this.questionIndex--;
                this.QuestionChange();
            },

            // 下一题    
            async nextQuestion() {
                this.questionIndex++;
                this.QuestionChange();
            },

            // 数据 改变    
            async QuestionChange() {
                // 数据还原    
                if (!!this.player) this.player.pause();
                if (!!this.Hint) this.Hint.pause();
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长    
                this.audioTime = 0; // 滑块 value    
                this.playertime = ""; //总时长    
                this.InPlay = false; // 判断是否 播放中    
                this.check = ""; // 判断是否选择 答案    
                this.complate = false; // 是否提交答案    
                this.answer = ""; // 答案    

                let itemM = this.Target.questionList[this.questionIndex];
                let mainId = '';
                if (this.Pattern == '测试模式' && !!this.Target.childList && this.Target.childList.length > 0) {
                    mainId = itemM.mainId;
                } else {
                    mainId = this.Target.sectionMain.id;
                }
                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId,
                    mainId);
                this.questionData = questiondata.data; // 当前问题 数据    
                if (this.Pattern == '测试模式') {
                    let dan = this.questionData.childQuestionList[itemM.num];
                    this.questionData.childQuestionList = [dan];
                }
                // 当前子问题数据信息    
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                this.childQuestionListAll = this.questionData.childQuestionList.length; // 当前问题 子题总数    
                var questionTextAndAudio = JSON.parse(this.childQuestionData.title);
                 this.questionTexts = questionTextAndAudio.questionText
                this.questionAudio = questionTextAndAudio.questionAudio;
                this.questionData.textResource = this.questionData.textResource.replace('/Images/', '');
                this.questionImg = this.$store.state.common.ImgUrl + this.questionData.textResource;
                console.log("图片地址：" + this.questionImg);
                // 录音查询   
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false; 
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if (!!record_.data && !!record_.data.filePath) {
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = "1";
                }else{
                    this.recorderPlayerUrl = null
                }

                // 查询答案
                // this.getAnswerData();

                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if (!!notes_.data && notes_.data.length > 0) {
                    this.NoteItem = notes_.data[0];
                } else {
                    this.NoteItem.content = "";
                }
                // 赋值音频路径
                this.QuestionAudioPlayerUrl = this.questionAudio
                // this.FlashPlay();    
                // this.QuestionParameter.autoplay = true;
                // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                // this.QuestionReady(); // 问题音频 设置
                this.InfoComplate = true;
            },

            // 上一子题

            prevChildQuestion() {
                this.childQuestionListIndex--;
                this.ChildDataChange();
            },

            // 下一子题    
            nextChildQuestion() {
                this.childQuestionListIndex++;
                this.ChildDataChange();
            },

            async ChildDataChange() {
                if (!!this.Question.audio) this.Question.audio.pause();
                this.complate = false; // 是否提交答案    
                this.answer = ""; // 答案    
                this.Question.ready = false;
                this.Question.status = false;

                this.NoteItem = {
                    //笔记    
                    id: "",
                    content: ""
                };
                // 当前子问题数据
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                var questionTextAndAudio = JSON.parse(this.childQuestionData.title);
                // this.questionText = questionTextAndAudio.questionText;    
                this.questionAudio = questionTextAndAudio.questionAudio;
                this.questionData.textResource = this.questionData.textResource.replace('/Images/', '');
                this.questionImg = this.$store.state.common.ImgUrl + this.questionData.textResource;
                 this.questionTexts = questionTextAndAudio.questionText
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if (!!notes_.data && notes_.data.length > 0) {
                    this.NoteItem = notes_.data[0];
                } else {
                    this.NoteItem.content = "";
                }
                // 查询答案
                this.getAnswerData();
                // 录音查询  
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;  
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if (!!record_.data && !!record_.data.filePath) {
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = "1";
                }else{
                    this.recorderPlayerUrl = null
                }
                // 赋值音频路径
                this.QuestionAudioPlayerUrl = this.questionAudio
                // if (this.Pattern == "测试模式") {
                //     this.QuestionParameter.autoplay = true;
                //     this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                //     // this.$message.info("将播放问题音频!");    
                // }
                // this.QuestionParameter.autoplay = true;
                // this.QuestionParameter.sources = [{ type:'rtmp/mp4',src:this.FlashUrl(this.questionAudio) }];
                // this.QuestionReady(); // 问题音频 设置    
            },

            // 音频 播放 暂停
            PlayPause(it) {
                if (this.Pattern == '测试模式') {
                    return;
                }
                if (this.ClickLimit) {
                    return false;
                }
                this.ClickLimit = true;
                let d = window.PlayerState(this.player.paused());
                if (d) {
                    this.player.play();
                    this.InPlay = true;
                } else {
                    if (this.RecordeItem.RecorderAudio == 1) {
                        this.RecordeItem.Media.pause();
                    }
                    if (this.RecordeItem.RecorderAudio == 2) {
                        Recorder.stop();
                    }
                    this.RecordeItem.MediaInPlay = false;
                    this.player.pause();
                    this.InPlay = false;
                }
                setTimeout(() => {
                    this.ClickLimit = false;
                }, 1000)
            },

            // 问题音频 描述设置    
            QuestionReady() {
                if(!!this.Question.audio){
                    this.Question.audio.src(this.QuestionParameter.sources);
                    if(this.QuestionParameter.autoplay){
                        this.Question.audio.play();
                    }
                    return false;
                }
                let item = JSON.parse(JSON.stringify(this.QuestionParameter));
                delete item.autoplay;
                this.Question.audio = videoJs('myElement',item);
                this.Question.audio.on('ready',()=>{
                    this.InPlay = true;
                    if(this.QuestionParameter.autoplay){
                        this.Question.audio.play();
                    }
                })
                this.Question.audio.on('canplaythrough',()=>{
                    this.InPlay = true;
                    if(this.QuestionParameter.autoplay){
                        this.Question.audio.play();
                    }
                })
                this.Question.audio.on('ended',()=>{
                    this.InPlay = false;
                    this.QuesetionComplete();
                })
                this.Question.audio.on('error', () => {
                    console.log('音频加载失败!');
                    let error = parseInt(sessionStorage.getItem('QuestionError'));
                    error++;
                    if (error > 3) {
                        sessionStorage.setItem('QuestionError', 0);
                        if (this.Pattern == '测试模式') {
                            this.$message.error('音频加载时失败,进入下一题!');
                            this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this
                                .questionIndex, this.questionAll);
                        }
                    } else {
                        this.$message.error('音频加载时失败,重新加载播放器!');
                        sessionStorage.setItem('QuestionError', error);
                        this.QuestionReady();
                    }
                })
            },
            // 提示音频播放 完成
            HintPlayerComplete() {
                if(this.BindRole.haveRecordDown == 1){
                }
            },
            QuesetionComplete() {
                let current = parseInt(sessionStorage.getItem('PlayTwice'));
                let {
                    isPlayTwice,
                    topicPlaybackInterval
                } = this.BindRole;
                if (isPlayTwice == 1 && current < 1) {
                    current++;
                    sessionStorage.setItem('PlayTwice', current);
                    let time = topicPlaybackInterval || 3;
                    time = parseInt(time) * 1000;
                    this.InPlay = true;
                    this.twicePlayTimeOut = setTimeout(this.ResetTime, time);
                } else {
                    console.log(this.BindRole.isNeedHintAudio,this.BindRole.hintAudio);
                    // 判断 是否有 提示音    
                    if (this.BindRole.isNeedHintAudio == 1) {
                        this.HintAudioPlayerUrl = this.BindRole.hintAudio//提示音频地址
                        this.HintAudioPlayerConfig.autoplay = true
                        // let item = {
                        //     width:'500',
                        //     height:'500',
                        //     controls:true,
                        //     loop:false,
                        //     preload:'auto',
                        //     autoplay:true,
                        //     sources:[
                        //         {
                        //             type:"rtmp/mp4",
                        //             src:this.FlashUrl(this.BindRole.hintAudio),
                        //         }
                        //     ],
                        // }
                        // if(!!this.Hint){
                        //     this.Hint.reset(); //重置 video
                        //     this.Hint.src([
                        //         {
                        //             type: 'rtmp/mp4',
                        //             src:this.FlashUrl(this.BindRole.hintAudio),
                        //         },
                        //     ]);
                        //     this.Hint.load();
                        //     this.Hint.currentTime(0);
                        //     this.Hint.play();
                        //     return false;
                        // }
                        // console.log(this.FlashUrl(this.BindRole.hintAudio),'提示音');
                        // this.Hint = videoJs('Hint',item);
                        // this.player.on('ended',()=>{
                        //     console.log('提示音播放完成')
                        //     this.InPlay = false;
                        // })
                    } else {
                        if(this.BindRole.haveRecordDown == 1){
                        }
                    }
                }
            },

            QuestionPlay() {
                if (this.Pattern == "测试模式") {
                    return;
                }
                if (!this.Question.audio.paused()) {
                    this.Question.audio.pause();
                    this.Question.state = false;
                    this.InPlay = false;
                } else {
                    this.Question.audio.play();
                    this.Question.state = true;
                    this.InPlay = true;
                }
            },

            // 题干音频播放 完成    
            _PlayerComplete() {
                console.log(this.Pattern);
                if (this.Pattern != "测试模式") {
                    return;
                }
                // this.$message("播放问题音频!");   
                setTimeout(() => {
                    this.Question.audio.play();
                    this.Question.status = true;
                }, 50)
            },

            On_back() {
                sessionStorage.setItem('PlayTwice', '0')
                this.ComplateTest = 0;
                this.T_back(
                    this.childQuestionListIndex,
                    this.childQuestionListAll,
                    this.questionIndex,
                    this.questionAll
                );
            },
            On_next() {
                sessionStorage.setItem('PlayTwice', '0')
                this.ComplateTest = 0;
                if (this.$store.state.common.MaxPattern == '闯关模式') {
                    if (!!this.childquestionDataCheck && this.childquestionDataCheck != '' && this.complate) {
                        this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                            .questionAll);
                    } else {
                        this.$message.warning('请先完成本题!');
                    }
                } else {
                    this.T_next(this.childQuestionListIndex, this.childQuestionListAll, this.questionIndex, this
                        .questionAll);
                }
            },
        },

        destroyed() {
            // 删除 播放器    
            if(!!this.Question.audio){
                this.Question.audio.dispose();
            }
            if(!!this.Hint){
                this.Hint.dispose();
            }
            clearInterval(this.wave);
            clearInterval(this.RecordeItem.Count);
        }

    };
</script>

<style scoped="less">
    .Unit_con .content_t{
        text-align: left;
    }
</style>